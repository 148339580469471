@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4691cz');
  src:  url('fonts/icomoon.eot?4691cz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?4691cz') format('woff2'),
    url('fonts/icomoon.ttf?4691cz') format('truetype'),
    url('fonts/icomoon.woff?4691cz') format('woff'),
    url('fonts/icomoon.svg?4691cz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="upd-"], [class*=" upd-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.upd-compass:before {
  content: "\e903";
}
.upd-download:before {
  content: "\e90b";
}
.upd-eye:before {
  content: "\e90c";
}
.upd-facebook:before {
  content: "\e907";
}
.upd-instagram:before {
  content: "\e908";
}
.upd-linkedin:before {
  content: "\e909";
}
.upd-twitter:before {
  content: "\e90a";
}
.upd-location:before {
  content: "\e906";
}
.upd-arrow-long:before {
  content: "\e900";
}
.upd-arrow:before {
  content: "\e901";
}
.upd-check:before {
  content: "\e902";
}
.upd-cross:before {
  content: "\e904";
}
.upd-info:before {
  content: "\e905";
}
